import React, {useState} from "react";

import {KeyboardDatePicker} from '@material-ui/pickers';
import {Alert, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {useNavigate, useParams} from 'react-router-dom';

const ProcessOptions = {
    "Weekly SILIBI Data": 91309,
    "Whats Hot Data": 91305,
    "SLT Collection" : 96925,
    "SDM Focus Collection": 111242
};

function loading() {
    return (<>
        <Spinner animation="grow" variant="primary"/>
        <Spinner animation="grow" variant="secondary"/>
        <Spinner animation="grow" variant="success"/>
        <Spinner animation="grow" variant="danger"/>
        <Spinner animation="grow" variant="warning"/>
        <Spinner animation="grow" variant="info"/>
        <Spinner animation="grow" variant="dark"/>
        <Alert>Just a moment while we load your link.... :)</Alert>
    </>)
}
function getLocalDate  (date)  {
    const timezoneOffset = date. getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - timezoneOffset);
    return localDate.toISOString().slice(0, 10);
}
function SelectDate() {
    const params = useParams();

    console.log("params:"+params)
    console.log(params)
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [process, setProcess] = useState("");
    const [showLoading, setLoading] = useState(false);
    const [link, setLink] = useState("");

    const handleStartDateChange = (date) => {
        setStartDate(date);

    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleProcessChange = (event) => {
        setProcess(event.target.value);
    };


    const handleSubmit = () => {
        if (!startDate || !endDate || !process) {
            alert("Please fill/select all fields.");
            return;
        }

        if (endDate < startDate) {
            alert("End date should be after start date.");
            return;
        }
        console.log(`Start date: ${getLocalDate(startDate)}`);

        console.log(`End date: ${getLocalDate(endDate)}`);
        console.log(`Process: ${ProcessOptions[process]}`);
        setLoading(true);
        setLink(null);
        axios.get('http://cnergy-erbackend.wooqer.com:8081/sessionLogin', {
            params: {
                startDate: getLocalDate(startDate),
                endDate: getLocalDate(endDate),
                evId: ProcessOptions[process],
            }, withCredentials: true,
            timeout: 6000000
        }).then((response) => {

            console.log(response.data)
            if (response.status <= 300) {
                setLoading(false);
                setLink(response.data);
            }

        }).catch((error) => {
            setLoading(false);
            alert(error.toString() + "\nPlease go back to login page");
            navigate('/');

        });
    };
    return (
        <div className={"vw-100"} style={{
            "padding": "150px",
            "color": "black",
            backgroundColor: "rgba(245,245,245,0.9)",
            opacity: 1,
            "display": "inline-block",
            "fontFamily": "monospace",
            "fontSize": "15px",
            "textAlign": "center",
        }}>
            <h1 style={{color: "black"}}>Select date and process</h1>
            <KeyboardDatePicker style={{margin: "10px"}}
                                label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                format="yyyy/MM/dd"
                                KeyboardButtonProps={{
                                    "aria-label": "change start date",
                                }}
            />
            <KeyboardDatePicker style={{margin: "10px"}}
                                label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                format="yyyy/MM/dd"
                                KeyboardButtonProps={{
                                    "aria-label": "change end date",
                                }}
            />

            <div style={{margin: 10}}>
                <label style={{fontFamily: "cursive", fontSize: 16, margin: 10}}>Process :</label>
                <select value={process} onChange={handleProcessChange}>
                    <option value="">--Please select a process--</option>
                    {Object.keys(ProcessOptions).map((process, index) => (
                        <option key={index} value={process}>
                            {process}
                        </option>
                    ))}
                </select>
            </div>
            <Button style={{margin: 10}} variant="primary" onClick={handleSubmit}>
                Submit
            </Button>
            {!startDate || !endDate || !process ? (
                <Alert variant="danger">
                    Please fill/select all fields.
                </Alert>
            ) : endDate < startDate ? (
                <Alert variant="danger">
                    End date should be after start date.
                </Alert>
            ) : null}
            <br/>
            {showLoading ? loading() : null}
            {link ? (link.startsWith("Success") ?
                <Alert variant="success"> Click <a href={link.split('Success:')[1].trim()}>here</a> to
                    download</Alert> : <Alert variant="warning">{link}</Alert>) : null}
        </div>
    );
}

export default SelectDate;
