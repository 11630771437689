import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert, Button, Form, FloatingLabel } from 'react-bootstrap';

const Login = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [response, setResponse] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            setError('Please fill in both email and password fields');
            return;
        }

        try {
            const { data, status, headers } = await axios.get('http://cnergy-erbackend.wooqer.com:8081/login', {
                params: { username: email, password },
                withCredentials: true,
            });

            if (status === 200) {
                console.log('Response headers:', headers);
                console.log('Response data:', data);

                const cookie = data?.cookie;
                if (cookie) {
                    document.cookie = cookie;
                    navigate('/selectDate');
                }
                setError('Success');
            } else {
                setError('Invalid response status');
            }
        } catch (err) {
            console.error('Error:', err);
            const errMsg = err.response?.data || 'Server Issue';
            setError(JSON.stringify(errMsg, null, 2));
        }
    };

    return (
        <div
            className="w-50"
            style={{
                padding: '100px',
                margin: '10px',
                color: '#262525',
                display: 'inline-block',
                fontFamily: 'monospace',
                fontSize: '18px',
                textAlign: 'center',
            }}
        >
            <h1 style={{ color: 'whitesmoke' }}>Log In To PantaLoons</h1>
            <br />
            <Form onSubmit={handleSubmit}>
                <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FloatingLabel>
                <br />
                <Button variant="primary" type="submit">
                    Login
                </Button>
                {error && (
                    <Alert
                        variant="danger"
                        style={{
                            fontSize: 16,
                            margin: 10,
                            fontFamily: 'monospace',
                        }}
                    >
                        {error}
                    </Alert>
                )}
            </Form>
        </div>
    );
};

export default Login;