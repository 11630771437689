import './App.css';
import Login from "./login";
import React from "react";
import SelectDate from "./selectDate";
import {Route, Routes} from "react-router-dom";
import wooqerLogo from "./wooqerLOGO.png";

function App() {
    return (

        <>

            <Routes>
                <Route exact path="/" element={<Login/>}/>
                <Route path="/selectDate" element={<SelectDate/>}/>
            </Routes>


            <div style={{

                position: 'fixed',
                bottom: 0,
                right: 0,
                margin: '20px',
                zIndex: 9999,
                fontStyle: "cursive",


            }}>
                <p>Powered by </p>
                <a href="https://www.wooqer.com" target="_blank" rel="noopener noreferrer">
                    <img style={{height: 30}} src={wooqerLogo} alt="Powered by Wooqer"/>
                </a>
            </div>
        </>
    );
}

export default App;
